@import "colors.scss";
.system-page {
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  // text-align: center;
  // background: $background no-repeat;
  // background: $primaryLightColor no-repeat;
  background-size: cover;
  padding: 30px;
  padding-top: 64px;

  .system-box {
    display: table-cell;
    vertical-align: middle;
    margin: auto;
    width: 360px;
    // border-radius: 15px 3px 15px 3px;
    &__container {
      // background-color: #f9fafb;
      // border: 3px solid $white;
      border-radius: 10px;
      padding: 20px 35px;
      // box-shadow: 10px 10px 10px -5px rgba(255, 217, 0, 0.2), 40px 40px 80px -40px rgba(116, 116, 116, 0.4);
      transition: all ease 200ms;
      // &.flat {
      //   box-shadow: 1px 1px 3px -1px rgba(255, 217, 0, 0.2), 2px 2px 10px -5px rgba(116, 116, 116, 0.4);
      // }
    }

    &--login {
      background: #fff;
      border-radius: 5px;
      padding: 20px;
    }

    &__signin {
      width: 540px;
    }

    .logo {
      margin: -60px auto 20px auto;
      svg {
        height: 40px;
        fill: black;
      }
    }
    form {
      max-width: 100%;
    }
    // .input-container {
    //   width: 100%;
    //   margin: 0 auto;
    //   input {
    //     width: 100%;
    //     border: none !important;
    //   }
    //   &-field {
    //     input {
    //       background: $primaryLightColor;
    //       color: #fff;
    //       &.Mui-focused {
    //         background: $primaryLightColor;
    //       }
    //     }
    //     label {
    //       color: #fff;
    //       font-weight: bold;
    //     }
    //   }
    //   &-button {
    //     background-color: $secondaryColor;
    //     color: #fff;
    //     & .Mui-disabled {
    //       background-color: $primaryLightColor !important;
    //       color: $primaryColor !important;
    //     }
    //   }
    //   &-forbidden-description {
    //     color: #fff;
    //     font-size: 1.5em;
    //     margin: 5vh 0;
    //   }
    // }

    .login-forgot {
      padding-left: 0;
      padding-right: 0;
      width: auto !important;
    }
    .message-error {
      // font-family: var(--text-primary-font);
      // font-size: 1.3em;
      // margin: 15px 0;
      // font-weight: lighter;
      font-family: var(--text-primary-font);
      font-size: 1.2em;
      margin: 15px 0;
      font-weight: normal;
      color: #777;
      text-align: justify;
    }
    .code-error {
      margin: 15px 0;
      font-size: 1em;
      color: var(--btn-dark-text);
      font-family: monospace;
    }
    .message-head {
      font-size: 1.5em;
      font-family: var(--text-primary-font);
      margin: 30px 0;
    }
    .message {
      margin: 20px auto;
      max-width: 500px;
      &.error-box {
        font-size: 0.8em;
        background-color: rgb(31, 66, 95);
        padding: 12px 20px;
        border-radius: 4px;
        text-align: left;
        @media (max-width: 767px) {
          width: 300px;
          overflow-wrap: anywhere;
        }
        .error-message {
          color: rgb(148, 192, 156);
          font-family: monospace;
        }
      }
    }
    .action {
      width: 300px;
      margin: 20px auto 0 auto;
      button {
        width: 100%;
      }
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// /* ScrollBar */
// // width
// ::-webkit-scrollbar {
//   width: 20px;
// }

// // Track
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 7px;
// }

// // Handle (thumb)
// ::-webkit-scrollbar-thumb {
//   border-radius: 7px;
//   background: url("../images/equals-sign.png") #3ac5cf;
//   background-size: 8px 16px;
//   background-repeat: no-repeat;
//   background-position: center;
// }

// // Handle on hover
// ::-webkit-scrollbar-thumb:hover {
//   background: url("../images/equals-sign.png") #31a5ad;
//   background-size: 8px 16px;
//   background-repeat: no-repeat;
//   background-position: center;
// }

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.boldText {
  font-weight: 700;
}

.bolderText {
  font-weight: 900 !important;
}

.redText {
  color: red;
}

.em2 {
  font-size: 2em;
}

.em1-3 {
  font-size: 1.3em;
}

.em2-5 {
  font-size: 2.5em;
}

.cursor-pointer {
  cursor: pointer;
}
