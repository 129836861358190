@import "../../assets/style/colors.scss";
.header {
  &--small-description {
    line-height: 0.5rem;
    margin-bottom: 0.5rem;
  }
  &--z-index {
    z-index: 100 !important;
    @media screen and (min-width: 900px) {
      display: none !important;
    }
  }
  &--mini {
    & > div {
      height: 100%;
    }
    background: $background;
    margin-top: 64px;
    height: 40px !important;
    min-height: 40px !important;
    z-index: -1;
    &-menu {
      align-content: center;
      &-div {
        padding-left: 20px;
        padding-right: 20px;
        height: 100% !important;
      }
      & > a {
        color: $secondaryTextColor;
        height: 100% !important;
        height: 100% !important;
      }
      background: $background;
      &-selected {
        background: var(--primary-dark);
        a {
          color: #fff;
          height: 100% !important;
        }
      }
    }
  }
  &--menu {
    background: $background;
    border-radius: 1rem 1rem 0 0;
    font-size: 0.95rem;

    &-div {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      height: 100% !important;
    }
    a {
      color: $secondaryTextColor;
      height: 100% !important;
      height: 100% !important;
    }
    &-selected {
      background: var(--primary-dark);
      a {
        color: #fff;
        height: 100% !important;
      }
    }
  }
}

.navbar-page-title {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &.long-text {
    span {
      font-size: 1.4rem;

      @media screen and (max-width: 1060px) {
        font-size: 1.13rem;
      }
    }
  }

  span {
    font-weight: bold;
    color: $darkGrey;
    font-size: 1.75rem;

    @media screen and (max-width: 1350px) {
      font-size: 1.4rem;
    }

    &:only-child {
      color: var(--primary-dark);
    }
  }

  span:last-child {
    color: var(--primary-dark);
  }
}
