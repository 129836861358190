@import "./colors.scss";
$primary: #363636;
$secondary: #898989;

* {
  font-family: "Texta", sans-serif !important;
}

.primary-text-color {
  color: var(--primary-dark);
}

.white-link {
  color: $white;
  text-decoration: none;
}

.yellow-link {
  color: #f3dc0c;
  text-decoration: none;
}

.admin {
  // Horizontal part of the menu (AdminLayout)
  &-header {
    // Background image on the horizontal menu
    // background-image: url(../../assets/images/header-small.svg);
    // background-position: center; /* Center the image */
    // background-size: cover; /* Resize the background image to cover the entire container */
    background-color: #fff;
    display: flex;
    align-items: flex-end !important; /* Vertically align the menu elements on the bottom of the menu */
    color: $secondaryTextColor;
    height: 64px !important;
    z-index: 100;
    &-profile {
      color: $white;
    }
    &-siderMenu {
      color: $white;
    }
  }

  // Big horizontal header
  &-header-big {
    height: 20vh;
    // Background image on the horizontal menu
    // background-image: url(../../assets/images/header-big.svg);
    // background-position: center; /* Center the image */
    // background-size: cover; /* Resize the background image to cover the entire container */
    background-color: $white;
    display: flex;
    align-items: flex-end !important; /* Vertically align the menu elements on the bottom of the menu */
    color: $white;
    &-profile {
      color: $white;
    }
    &-siderMenu {
      color: $white;
    }
  }

  &-main-title {
    color: $primary;
    font-family: "Texta Book", sans-serif !important;
    font-style: normal;
    font-weight: 350;
    padding-left: 0px !important;
    margin-left: 8px !important;
    h2 {
      font-size: 2rem;
      margin: 5px;
      margin-bottom: 0px;
      line-height: 2.2rem !important;
    }
    small {
      margin-left: 25px;
    }
    h4 {
      font-size: 1.2rem;
    }
  }

  &-divider-text {
    color: $primary;
    font-size: 1.2rem;
  }

  &-main {
    margin-top: 10vh !important;
  }

  &-layout {
    &-mainMenu > .MuiDrawer-paperAnchorLeft {
      border: none;
      span {
        color: $primary !important;
        .MuiTypography-root {
          color: $primary;
          font-weight: bold;
        }
        svg {
          fill: $primary !important;
        }
        text-decoration: none !important;
      }
      ul {
        .mainSelected {
          color: $secondary;
          .MuiTypography-root {
            color: #127ec9 !important;
          }
          svg {
            fill: $secondary !important;
          }
        }
      }
    }
    &-box {
      // vertical part of the menù (AdminLayout)
      background-color: $white;
      box-shadow: inset -10px 0 20px -10px
        darken($color: $pageBackgroundColorPrimary, $amount: 8);
      height: 100vh - 8vh;
      // padding-top: 10vh;
      & > div {
        // hidden horizontal part of the menù (AdminLayout)
        // background-color: $secondaryColor;
        & > button {
          color: #202b35 !important;
        }
      }
      &-container {
        // padding: 10px;
        // padding-top: 0 !important;
        // margin-top: 10vh;
        padding: 0px;
        background-color: $background;
      }
    }
  }

  &-open-subMenu {
    padding-left: 220px;
  }

  // Modal Dialog
  &-dialog {
    &-title {
      background-color: rgba(var(--primary-rgb), 0.8);
      &-string {
        color: #29313a;
        text-align: center;
      }
    }
  }

  &-table {
    &-thead {
      &-row {
        background-color: $background;
        th {
          color: #fff !important;
        }
      }
    }

    &-tbody {
      tr:nth-child(even) {
        background-color: #fff !important;
      }
    }
  }

  &-backIcon {
    position: absolute;
    top: 10px;
    left: 10px;
    // color: #fff;
    font-size: 40px;
  }

  &-landing {
    h1,
    a {
      color: #fff;
    }
  }

  &-recaptcha {
    > div {
      display: flex;
      justify-content: center;
    }
  }

  &-list {
    .ant-tag {
      min-width: 100px;
      text-align: center;
      border-radius: 8px 0;
    }
  }

  &-bitTitle {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h1 {
      position: relative;
      z-index: 1;
      cursor: pointer;
      margin-bottom: 0;
      margin-top: 0;
    }

    h3 {
      position: relative;
      z-index: 1;
      cursor: pointer;
      margin-bottom: 0;
      margin-top: 0;
    }

    &-line {
      width: 2px;
      height: 50%;
      background-color: $secondaryColor;
    }

    &-text {
      margin: auto 0;
      padding: 0 60px 0 0;
      text-align: justify;
      @media (max-width: 576px) {
        padding: 0px;
        width: 100% !important;
      }
    }
  }

  &-user {
    &-landing {
      &__left {
        height: calc(100vh - 150px);
        display: flex;
        @media (max-width: 576px) {
          display: block;
        }
      }

      &__right {
        display: flex;
        @media (max-width: 576px) {
          display: block;
        }
      }
    }

    &-steps {
      &-content {
        height: calc(100% - 110px);
        @media (max-width: 576px) {
          height: initial;
        }

        &__inner {
          height: calc(100% - 60px);
          @media (max-width: 576px) {
            height: initial;
          }
        }

        section {
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }

  &-chip {
    min-width: 120px;
    text-align: center;
    border-radius: 8px 0 !important;
    border: 1px solid !important;
    height: 23px !important;

    &--trucated {
      @media (max-width: 576px) {
        max-width: 220px;
      }
    }

    &--PROCESSED,
    &--ADMIN {
      color: $processedColor !important;
      background-color: $processedBackground !important;
      border-color: $processedBorder !important;
    }

    &--SUBMITTED,
    &--APPLICANT {
      color: $submittedColor !important;
      background-color: $submittedBackground !important;
      border-color: $submittedBorder !important;
    }

    &--PROCESSING,
    &--INVESTIGATOR {
      color: $processingColor !important;
      background-color: $processingBackground !important;
      border-color: $processingBorder !important;
    }

    &--ACCEPTED {
      color: $acceptedColor !important;
      background-color: $acceptedBackground !important;
      border-color: $acceptedBorder !important;
    }

    &--PROCESS_ERROR {
      color: $processErrorColor !important;
      background-color: $processErrorBackground !important;
      border-color: $processErrorBorder !important;
    }

    &--REJECTED {
      color: $rejectedColor !important;
      background-color: $rejectedBackground !important;
      border-color: $rejectedBorder !important;
    }
  }

  &-filter {
    &__button {
      &--active {
        text-decoration: underline !important;
      }
    }
  }
}

.Mui-disabled {
  // opacity: 0.3;
  // background-color: $halfFadedColor;
  // color: white;
  opacity: 1;
  background-color: #eeeeee9d;
  border-color: transparent;

  input {
    -webkit-text-fill-color: $primaryTextColor !important;
  }
}
label.Mui-disabled {
  background-color: transparent;
}

.MuiFormLabel-root.Mui-required {
  span {
    color: $error;
  }
}

// .MuiChip-filled {
//   background-color: $secondaryColor !important;
//   color: $primaryDarkColor !important;
//   font-weight: bold;
// }

.Mui-disabled {
  .table-pagination {
    color: $primaryLightColor !important;
  }
}
.table-pagination {
  color: var(--primary) !important;
}

.list-grid {
  background-color: $white;
  border-radius: 1rem !important;
  border: none !important;

  &--title {
    font-size: 1.35rem;
    color: var(--primary-dark);
  }

  .MuiDataGrid {
    &-columnHeaderTitle {
      color: var(--primary);
    }

    &-columnHeaders {
      border-bottom: none;
      font-size: 1.1rem;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--primary);
        transform: scaleY(0.8);
        transform-origin: bottom;
      }
    }

    &-cell:focus {
      outline: none;
    }

    &-row {
      position: relative;
      border-bottom: none;
      font-weight: bold;
      font-size: 1.1rem;

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--primary);
        transform: scaleY(0.6);
        transform-origin: bottom;
      }
    }

    &-iconSeparator {
      display: none;
    }
  }
}

//MUI DATA GRID CUSTOM
// .MuiDataGrid {
//   &-root {
//     border: none !important;
//   }
//   &-columnHeaders {
//     background: transparent !important;
//     color: black !important;
//     border: none !important;
//     min-height: 42px !important;
//     max-height: 42px !important;
//   }
//   &-columnHeader,
//   &-columnHeaderDraggableContainer,
//   &-columnHeaderTitleContainer,
//   &-columnHeaderTitle {
//     min-height: 42px !important;
//     max-height: 42px !important;
//     font-weight: 800 !important;
//     font-size: 1.1rem !important;
//   }
//   &-virtualScroller {
//     margin-top: 42px !important;
//   }
//   &-columnSeparator {
//     opacity: 0 !important;
//   }
//   &-row {
//     margin-top: 7.5px !important;
//     margin-bottom: 7.5px !important;
//     background: #fff;
//     box-shadow: 1px 2px 1px 0px rgba(224, 222, 222, 0.452);
//     -webkit-box-shadow: 1px 2px 1px 0px rgba(224, 222, 222, 0.452);
//     -moz-box-shadow: 1px 2px 1px 0px rgba(224, 222, 222, 0.452);
//     border-radius: 6px;
//     min-height: 56px !important;
//     max-height: 56px !important;
//   }
//   &-cell {
//     min-height: 56px !important;
//     max-height: 56px !important;
//   }
// }

// change the color of the detail action btn in the table
.MuiDataGrid-virtualScroller {
  div.MuiDataGrid-cell {
    a {
      svg {
        fill: var(--primary-dark);
      }
    }
  }
}

.remove-link-style {
  text-decoration: none !important;
}

// .MuiFormLabel-root .Mui-disabled {
//   color: $primaryDarkColor !important;
// }

.scroll-desktop {
  overflow-y: scroll !important;
  @media (max-width: 576px) {
    overflow-y: visible !important;
  }
}

.led {
  margin: 0 5px;
  width: 10px;
  height: 10px;

  border-radius: 50%;
  &-red {
    background-color: $error;
    box-shadow:
      $ledBaseShadow 0 -1px 7px 1px,
      inset $ledRedShadowFirst 0 -1px 9px,
      $ledRedShadowSecond 0 2px 5px;
  }
  &-green {
    background-color: $success;
    box-shadow:
      $ledBaseShadow 0 -1px 7px 1px,
      inset $ledGreenShadowFirst 0 -1px 9px,
      $ledGreenShadowSecond 0 2px 5px;
  }
  &-yellow {
    background-color: $warning;
    box-shadow:
      $ledBaseShadow 0 -1px 7px 1px,
      inset $ledYellowShadowFirst 0 -1px 9px,
      $ledYellowShadowSecond 0 2px 5px;
  }

  &-blue {
    background-color: $info;
    box-shadow:
      $ledBaseShadow 0 -1px 7px 1px,
      inset $ledBlueShadowFirst 0 -1px 9px,
      $ledBlueShadowSecond 0 2px 5px;
  }

  &-lightGreen {
    background-color: rgb(179, 235, 179);
    box-shadow:
      rgb(0 0 0 / 20%) 0 -1px 7px 1px,
      inset rgb(128, 191, 128) 0 -1px 9px,
      rgba(179, 235, 179, 0.607) 0 2px 5px;
  }
  &-lightGray {
    background-color: lightGray;
    box-shadow:
      rgb(0 0 0 / 20%) 0 -1px 7px 1px,
      inset rgb(155, 155, 155) 0 -1px 9px,
      rgba(211, 211, 211, 0.63) 0 2px 5px;
  }
}

.strong {
  font-size: 1.2em;
  font-style: italic;
  color: var(--primary);
}

.notFound {
  &-container {
    background-color: "#fff";
    border: 5px solid var(--primary-dark);
    border-radius: 10px;
    height: 60vh;
    margin-top: 20px;
  }
  &-icon {
    font-size: 4rem;
    color: var(--primary);
    margin: 0px;
    font-weight: bolder;
  }
  &-text {
    font-size: 2rem;
    color: var(--primary);
    margin: 0px;
  }
}

.btn {
  font-size: 1.3rem !important;
  border-radius: 0.75rem !important;
  box-shadow: none !important;
}

.as-btn {
  color: var(--primary);
  font-size: 0.875rem;
  font-weight: 500;
  padding: 6px 8px;
  line-height: 1.75;

  &:hover {
    color: $white;
    background-color: var(--primary-dark);
  }
}

.btn-small {
  font-size: 1.05rem !important;
}

.btn-primary {
  background-color: var(--primary) !important;

  &:disabled {
    background-color: rgba(var(--primary-rgb), 0.5) !important;
    color: $white !important;
  }
}

.btn-outlined {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  background-color: $white !important;

  &:hover {
    background-color: var(--primary) !important;
    color: $white !important;
    border-color: var(--primary) !important;
  }

  &:disabled {
    background-color: $lightGrey !important;
  }
}

.btn-empty {
  color: var(--primary) !important;
  background-color: $white !important;

  &:hover {
    background-color: var(--primary) !important;
    color: $white !important;
  }
}

.btn-error {
  background-color: $red !important;

  &:hover {
    background-color: #bf0f32 !important;
  }
}

.backIconButton,
.backIcon {
  color: var(--primary-dark) !important;
}
