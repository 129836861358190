.animation {
  animation-fill-mode: both;
  animation-duration: 800ms;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  // Accessibility for reduced motion
  @media (prefers-reduced-motion) {
    animation-name: fadein !important;
  }
  &:not(.animate-only-this) > * {
    animation: inherit;
    animation-fill-mode: both;
    animation-duration: 800ms;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &.slow {
    animation-duration: 1.5s !important;
    &:not(.animate-only-this) > * {
      animation-duration: 1.5s !important;
    }
  }
  &.fast {
    animation-duration: 250ms !important;
    &:not(.animate-only-this) > * {
      animation-duration: 250ms !important;
    }
  }
  &.faster {
    animation-duration: 150ms !important;
    &:not(.animate-only-this) > * {
      animation-duration: 150ms !important;
    }
  }
  &.short-delay {
    animation-delay: 350ms;
    &:not(.animate-only-this) > * {
      animation-delay: 350ms;
    }
  }
  &.long-delay {
    animation-delay: 1.5s;
    &:not(.animate-only-this) > * {
      animation-delay: 1.5s;
    }
  }
  &.scale-center-fade {
    animation-name: scale-center-fade;
  }
  &.fadein {
    animation-name: fadein;
  }
  &.fadein-right {
    animation-name: fadein-right;
  }
  &.fadein-right-long {
    animation-name: fadein-right-long;
  }
  &.fadein-left {
    animation-name: fadein-left;
  }
  &.fadein-left-long {
    animation-name: fadein-left-long;
  }
  &.fadein-up {
    animation-name: fadein-up;
  }
  &.fadein-up-long {
    animation-name: fadein-up-long;
  }
  &.fadein-down {
    animation-name: fadein-down;
  }
  &.fadein-down-long {
    animation-name: fadein-down-long;
  }
  &.fadein-down-full {
    animation-name: fadein-down-full;
  }
  &.fadein-blur {
    animation-name: fadein-blur;
  }
  &.fadein-blur-up {
    animation-name: fadein-blur-up;
  }
  &.fadein-blur-up-long {
    animation-name: fadein-blur-up-long;
  }
  &.blurin {
    animation-name: blurin;
  }
  &.hover {
    &-slide {
      &-left:hover {
        margin-left: (-3px);
        margin-right: (-3px);
      }
    }
  }
  .animation {
    animation-delay: 450ms;
    &.short-delay {
      animation-delay: 350ms;
    }
    &.long-delay {
      animation-delay: 1.5s;
    }
  }
}
@for $i from 1 through 100 {
  .animation .animation:nth-child(#{$i}n) {
    animation-delay: #{$i * 100}ms !important;
  }
}

/* ANIMATIONS */
@keyframes scale-center-fade {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein-right {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes fadein-right-long {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes fadein-left {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes fadein-left-long {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes fadein-up {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadein-up-long {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadein-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadein-down-long {
  0% {
    opacity: 0;
    transform: translateY(-300px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadein-down-full {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadein-blur {
  0% {
    filter: blur(5px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes fadein-blur-up {
  0% {
    transform: translateY(10px);
    filter: blur(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes fadein-blur-up-long {
  0% {
    transform: translateY(300px);
    filter: blur(5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes blurin {
  0% {
    filter: blur(3px);
  }
  100% {
    filter: blur(0px);
  }
}
