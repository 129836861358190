:root {
  // --primary: #5bc6b8;
  // --primary-rgb: 91, 198, 184;
  // --primary-dark: #00897b;
  // --primary-dark-rgb: 0, 128, 124
  --primary-dark: #13b6de;
  --primary-dark-rgb: 19, 182, 222;
  --primary: #12d2ff;
  --primary-rgb: 18, 210, 255;
}

$pageBackgroundColorPrimary: #fff;
$a: #3c77a3;
$primaryColor: #008a7b;
$primaryLightColor: #04b39eb9;
$primarySemiTrasparent: #04b39f71;
$primaryDarkColor: #017c6d;
$secondaryColor: #64c7b8;
$primaryTextColor: #808080;
$secondaryTextColor: #0f1515;
$backgroundLight: #f3f3f3dd;
$background: #eeeeee;
$backgroundDark: #d7d7d7;
$bgModal: #fff;

$fadedColor: #eeeeee;
$halfFadedColor: #ebebeb;

$accentColor: #43ced8;

$lightGreen: #5bc6b8;
$darkGreen: #00897b;
$darkGrey: #747474;
$red: #f92d55;

$CSA: #419a6a;
$CSB: #6dc798;
$CSC: #f4c633;
$CSD: #f7941c;
$CSE: #ef2d25;

$error: #ff0000;
$success: #80d51a;
$warning: #feff38;
$info: #729fcf;
$white: #fff;
$orange: #ff5429;
$darkColor: #282828;
$lightGrey: #ccc;
$gradient: linear-gradient(
  90deg,
  #e5005b 0%,
  #df035d 18%,
  #cf0a64 41%,
  #b4166f 68%,
  #8e287f 96%,
  #892a81 100%
);
$processedColor: #531dab;
$processedBackground: #f9f0ff;
$processedBorder: #d3adf7;
$submittedColor: #096dd9;
$submittedBackground: #e6f7ff;
$submittedBorder: #91d5ff;
$processingColor: #1d39c4;
$processingBackground: #f0f5ff;
$processingBorder: #adc6ff;
$acceptedColor: #00673a;
$acceptedBackground: #cffce5;
$acceptedBorder: #00673a;
$processErrorColor: #d33203;
$processErrorBackground: #ffe3da;
$processErrorBorder: #d33203;
$rejectedColor: #70000f;
$rejectedBackground: #ffdbe0;
$rejectedBorder: #70000f;
$ledBaseShadow: rgba(0, 0, 0, 0.2);
$ledRedShadowFirst: #aa1414;
$ledRedShadowSecond: rgba(255, 0, 0, 0.568);
$ledGreenShadowFirst: #179228;
$ledGreenShadowSecond: rgba(11, 153, 46, 0.5);
$ledYellowShadowFirst: #b7b928;
$ledYellowShadowSecond: #b6b9289a;
$ledBlueShadowFirst: #308ca3;
$ledBlueShadowSecond: #308ca3b4;

@mixin shadow($scolor: #000, $size) {
  @if $size == "sm" {
    box-shadow: 0.5px 1px 1px rgba($scolor, 0.7);
  }
  @if $size == "md" {
    box-shadow:
      1px 2px 2px rgba($scolor, 0.333),
      2px 4px 4px rgba($scolor, 0.333),
      3px 6px 6px rgba($scolor, 0.333);
  }
  @if $size == "lg" {
    box-shadow:
      1px 2px 2px rgba($scolor, 0.2),
      2px 4px 4px rgba($scolor, 0.2),
      4px 8px 8px rgba($scolor, 0.2),
      8px 16px 16px rgba($scolor, 0.2),
      16px 32px 32px rgba($scolor, 0.2);
  }
}

.bshadow {
  $sgrey: #3366cc;
  &-sm {
    @include shadow($sgrey, "sm");
  }
  &-md {
    @include shadow($sgrey, "md");
  }
  &-lg {
    @include shadow($sgrey, "lg");
  }
}

.white {
  color: #fff;
}

.primaryColor {
  color: $primaryTextColor;
}

.CSA {
  color: $CSA;
}
.CSB {
  color: $CSB;
}
.CSC {
  color: $CSC;
}
.CSD {
  color: $CSD;
}
.CSE {
  color: $CSE;
}
